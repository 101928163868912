@font-face {
    font-family: 'nutcoteregular';
    src: url('./fonts/nutcote-roman-webfont.woff2') format('woff2'),
         url('./fonts/nutcote-roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
body {
    font-family: "proxima-nova", sans-serif;
    font-weight: 300;
    color: #606161;
    letter-spacing: 2px;
    font-size: 1em;
}
h1 {
    font-family: 'Lora', serif;
    font-size: 60px;
    color: #D8A14A;
    letter-spacing: 2px;
    margin-bottom: 0;
}
h2, h3, h4 {
    color: #D8A14A;
    letter-spacing: 2px;
    font-weight: normal;
}
h2 { font-size: 1.5em; }
h3 { font-size: 1.3em; }

.lora-font {
    font-family: 'Lora', serif;
}
.clear-float {
    clear:both;
}

/** text-styles **/
.highlight {
    color: #D8A14A;
}

/** buttons **/
a,
a:visited {
    color: inherit;
    text-decoration-color: #D8A14A;
}

a.button,
.button,
a.map-button,
a.dark-button,
a.gold-button,
button {
    display: inline-block;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
    background-color: transparent;
    border: 1px solid #16423e;
    margin: 5px 10px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
a.button,
.button {
    color: #16423e;
}

a.button:hover,
.button:hover,
a.dark-button,
.dark-button,
button {
    color: #FFF;
    background-color: #16423e;
}

a.dark-button:hover,
.dark-button:hover,
button:hover {
    color: #16423e;
    background-color: #FFF;
}
a.gold-button,
.gold-button {
    color: #FFF;
    background-color: #CC9946;
}
a.gold-button:hover,
.gold-button:hover {
    color: #CC9946;
    background-color: #FFF;
}

/** imgs **/
.page-body img {
    border-color: #FFF;
}
img.display-left {
    max-width: 50% !important;
    float:left;
    margin-right:20px;
    margin-bottom:20px;
}
img.display-right {
    max-width: 50% !important;
    float:right;
    margin-left:20px;
    margin-bottom:20px;
}

/** blocks **/
.orange-block {
    position: relative;
    width: 100%;
    background-color: #F8DDA0;
    padding: 40px 0;
    padding-bottom: 60px;
    margin: 20px 0;
    z-index:1;
}
.page-body .orange-block p,
.page-body .orange-block h2,
.page-body .orange-block h3,
.page-body .orange-block h4 {
    text-align: center;
    max-width: 600px;
    margin: 20px auto;
}

/* inline blocks */
.map-block {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: 250px;
    margin: 20px;
}

.donation { /* gumnut */
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    color: #CC9946;
    font-size: 18px;
    width: 200px;
    padding-top: 160px;
    box-sizing: border-box;
    margin: 20px 2%;
}
.donation:before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    display: block;
    width: 100%;
    height: 160px;
    background-image:url('../assets/gumnut.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}
.page-body .donation p {
    text-align: center;
}
.donation p:first-of-type {
    font-family: "nutcoteregular", sans-serif;
    letter-spacing: 1px;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    font-size: 25px;
    padding-right: 30px;
    line-height: 1;
    box-sizing: border-box;
}
.donation a {
    text-decoration: none;
}
.donation .button,
.donation .dark-button,
.donation .gold-button {
    margin: 10px 0;
}



/*** media **/
@media only screen and (max-width:800px) {
    a.button,
    .button,
    a.map-button,
    a.dark-button,
    a.gold-button,
    button {
        padding: 10px;
        max-width: 100%;
    }
}

@media only screen and (max-width:760px){
    .map-block {
        width: 100%;
        margin: 0;
    }
    img.display-left,
    img.display-right {
        display: block;
        max-width: 100% !important;
        float: none;
        margin: 20px 0;
    }
    .page-body img {
        float: none;
        margin: 20px 0;
    }
}
